import NavBar from "./NavBar"
import React from "react"

const Header = ({ pageContext, globalState, SearchClass }) => (
  <header>
    <div className="container">
      <div className="menu">
        <NavBar pageContext={pageContext} globalState={globalState} />
      </div>
    </div>
  </header>
)
export default Header
