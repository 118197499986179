import React from "react"
import { Link } from "gatsby"
// import NavbarItem from "./NavbarItem";
// import NavbarBurger from "./NavbarBurger";

export default class CatNavbar extends React.Component {
  state = {
    activeAccordion: "-1",
  }
  toggleAccordion = (e, d) => {
    const which = e.currentTarget.getAttribute("data-idx")
    //console.log("toggleAccordion, e.currentTarget=", which, "d=", d)
    this.setState({
      activeAccordion: which !== this.state.activeAccordion ? which : "-1",
    })
  }

  getCategories = ({pageContext}) => (
    Array.from(
      new Set(
        pageContext.allPosts.reduce(
          (resultat, { category }) =>
            resultat.concat(
              JSON.stringify({
                title: category.frontmatter.title,
                slug: category.fields.slug,
              })
            ),
          []
        )
      )
    ).map(catJson => JSON.parse(catJson))
  )

  getSubCategories = ({pageContext}) => (
    Array.from(
      new Set(
        pageContext.allPosts.reduce(
          (resultat, { subcategory }) =>
            resultat.concat(
              JSON.stringify({
                title: subcategory.frontmatter.title,
                slug: subcategory.fields.slug,
              })
            ),
          []
        )
      )
    ).map(subCatJson => JSON.parse(subCatJson))
  )

  render() {
    const pageContext = this.props.pageContext
    const categories = (this.props.pageContext && this.props.pageContext.allPosts ? this.getCategories(this.props) : [])
    const subCategories = (this.props.pageContext && this.props.pageContext.allPosts ? this.getSubCategories(this.props) : [])
    return (
      <React.Fragment>
        {categories.map((category, idx) => {
            const subCategoriesLinked = subCategories.filter(subCategory =>
              subCategory.slug.includes(category.slug)
            )
            return (
              <div key={idx} className="navbar-item has-dropdown is-hoverable">
                <Link className="navbar-link" to={"/" + pageContext.language + category.slug+"/"}>
                  {category.title}
                </Link>
                <div className="navbar-dropdown">
                  {subCategoriesLinked.map((subCategoryLinked, idx1) => (
                    <Link
                      key={idx1}
                      className="navbar-item"
                      to={"/" + pageContext.language + subCategoryLinked.slug+"/"}
                    >
                      {subCategoryLinked.title}
                    </Link>
                  ))}
                </div>
              </div>
            )
        })}
      </React.Fragment>
    )
  }
}
