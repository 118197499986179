import React from "react"
//import { Link } from "gatsby"
// import NavbarItem from "./NavbarItem";
// import NavbarBurger from "./NavbarBurger";

const NavbarBurger = props => (
  <button
    onClick={props.toggleMenu}
    className={`button navbar-burger ${props.active ? "is-active" : ""}`}
  >
    <span />
    <span />
    <span />
  </button>
)

export default NavbarBurger
