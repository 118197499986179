import React from "react"
import { Link } from "gatsby"
import NavbarBurger from "../components/NavBarBurger"
import CatNavBar from "../components/CatNavBar"
// import NavbarItem from "./NavbarItem";
// import NavbarBurger from "./NavbarBurger";

export default class Navbar extends React.Component {
  state = {
    activeMenu: false,
    activeSelector: false,
  }
  
  toggleMenu = () => {
    this.props.globalState.updateState({menuActif: !this.state.activeMenu})
    this.setState({
      activeMenu: !this.state.activeMenu,
    }) 
  }
  toggleSelector = () => {
    this.setState({
      activeSelector: !this.state.activeSelector,
    })
  }

  render() {
    const pageContext = this.props.pageContext
    const link = pageContext.language ? pageContext.language : false
    const actualLang = pageContext.language ? pageContext.language.toUpperCase() : false
    return (
      <nav className="navbar">
        <div className="navbar-brand">
          <Link to={link ? ("/"+link+"/") : "/fr/"} className="navbar-item logo">
            <svg viewBox="0 0 424 88">
              <path d="M93.37 56.93c-10.01-5.55-14.96-5.23-29.22-2.77-18.2 3.4-36.83-19.87-22.95-23.34 17.18-3.28 47.48-3.16 52.17 26.11zm-1.65 2.66c-3.61 1.28-5.15 3.14-6.32 4.55-.78.94-1.4 1.7-2.36 1.94-6.72 1.64-21.11-4.29-16.93-7.06 6.6-4.6 20.54-4.92 25.61.57zm-59.2-34.83C-4.63 27.16-5.08 4.01 7.16 1.8 39.5-3.75 111.56 6.66 95.6 53.4c-2.42-17.28-22.07-30.72-48.7-29.7-6.66.24-9.32.81-14.4 1.06zm160.43 6.79c.13 1.32.25 2.7.25 4.16 0 17.16-13.18 31.1-30.42 31.1-18.83 0-33.47-14.58-33.47-32.49 0-17.91 15.34-32.48 34.17-32.48 6.46 0 14.45 2.33 19.77 6.56l-7.1 10.78a19.52 19.52 0 0 0-12.61-4.6c-10.97 0-20.03 8.77-20.03 19.8 0 11.04 8.3 19.81 19.33 19.81 7.1 0 13.31-3.72 15.09-9.9h-17.37V31.55h32.4zm35.75-8.52h15.66l-27 64.97h-15.6l11.48-25.23-17.12-39.74h15.6l8.49 23.46 8.5-23.46zm71.38-1.39c8.93 0 16.67 6.56 16.67 19.8v23.98h-14.2V42.77c0-7.06-2.6-8.45-5.7-8.45-2.92 0-6.4 1.96-6.4 9.9v21.2H276.3V42.77c0-7.06-2.6-8.45-5.7-8.45-2.93 0-6.41 1.96-6.41 9.9v21.2H250V23.09h14.2v4.23c3.04-3.53 5.9-5.68 11.03-5.68 4.88 0 8.75 1.96 11.47 5.74 3.8-4.35 9-5.74 13.38-5.74zM326 .44h14.2v64.98H326V.45zm31.95 16.98a8.47 8.47 0 1 1 0-16.97 8.49 8.49 0 1 1 0 16.97zm-7.1 48V23.03h14.2v42.39h-14.2zM389.96.45v26.87c3.17-3.85 7.8-5.68 12.68-5.68C414.8 21.64 424 31.8 424 44.3c0 12.49-9.2 22.58-21.36 22.58-4.88 0-9.51-1.9-12.68-5.68v4.23h-14.2V.45h14.2zm9.95 53.68a9.9 9.9 0 0 0 0-19.8 9.9 9.9 0 1 0 0 19.8z"></path>
            </svg>
          </Link>
          <NavbarBurger
            active={this.state.activeMenu}
            toggleMenu={this.toggleMenu}
          />
        </div>
        <div
          className={`navbar-menu navbar-end ${
            this.state.activeMenu ? "is-active" : ""
            }`}
        >
          <CatNavBar pageContext={pageContext} />
          {pageContext.language ? 
          (<div className="navbar-item"> 
            <div className={this.state.activeSelector ? "dropdown is-active" : ""} onClick={this.toggleSelector} onKeyDown={this.toggleSelector} role="button" tabIndex={0}>
              <div className="dropdown-trigger">
                <button className="button lang-select" aria-haspopup="true" aria-controls="dropdown-lang-select">
                  <span>{actualLang}</span>
                </button>
              </div>
              <div className="dropdown-menu" id="dropdown-lang-select" role="menu">
                <div className="dropdown-content">
                  <Link to="/fr/" className="navbar-item is-fr">
                    FR
                  </Link>
                  <Link to="/en/" className="navbar-item is-en">
                    EN
                  </Link>
                  <Link to="/nl/" className="navbar-item is-nl">
                    NL
                  </Link>
                </div>
              </div>
            </div>
          </div>) 
          : ""}
          {pageContext.navLink ? 
          (<a href={pageContext.navLink} className="navbar-item cta">
            {pageContext.navLinkText}
          </a>) 
          : ""}
        </div>
      </nav>
    )
  }
}
