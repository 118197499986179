/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import SEO from "./seo"
import "./layout.css"
//import CatNavBar from "../components/CatNavBar"

const Layout = ({
  children,
  pageContext,
  seotitle,
  description,
  keywords,
  image,
  imageTwitter,
  globalState,
  Container,
  SearchClass,
}) => {
  return (
    <>
      <SEO
        title={seotitle}
        image={image}
        imageTwitter={imageTwitter}
        description={description}
        keywords={keywords}
        language={pageContext.language}
        enVersion={pageContext.en_version}
        enUrl={pageContext.en_url}
        frVersion={pageContext.fr_version}
        frUrl={pageContext.fr_url}
        nlVersion={pageContext.nl_version}
        nlUrl={pageContext.nl_url}
        path={pageContext.breadcrumb.location}
        canonicalUrl={pageContext.canonical_url}
      />
      <Header
        globalState={globalState}
        pageContext={pageContext}
        SearchClass={SearchClass}
      />
      <main className={globalState && globalState.menuActif ? "is-active" : null}>
        <div className={Container}>{children}</div>
      </main>
      <footer className="footer has-text-white">
        <div className="container content">
          © 2013 - {new Date().getFullYear()} Gymlib
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
