/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, meta, title, imageTwitter, keywords, index, language, enVersion, enUrl, frVersion, frUrl, nlVersion, nlUrl, path, canonicalUrl }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
          }
        }
      }
    `
  )
  //console.log("hreflang:",enVersion, enUrl, frVersion, frUrl, nlVersion, nlUrl)
  const metaDescription = description
  return (
    <Helmet
      htmlAttributes={{
        lang: language,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keywords,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image`,
          content: 'https://blog.gymlib.com/img/' + imageTwitter,
        },
        {
          property: `og:image`,
          content: 'https://blog.gymlib.com/img/' + imageTwitter,
        },
        {
          property: `og:image:secure_url`,
          content: 'https://blog.gymlib.com/img/' + imageTwitter,
        },
        {
          property: `robots`,
          content: index,
        },
      ].concat(meta)}
    >
      {enVersion ? (<link rel="alternate" href={"https://blog.gymlib.com"+enUrl+"/"} hrefLang="en" />) : ""}
      {frVersion ? (<link rel="alternate" href={"https://blog.gymlib.com"+frUrl+"/"} hrefLang="fr" />) : ""}
      {nlVersion ? (<link rel="alternate" href={"https://blog.gymlib.com"+nlUrl+"/"} hrefLang="nl-be" />) : ""}
      {canonicalUrl ? (<link rel="canonical" href={"https://blog.gymlib.com"+canonicalUrl} />) : (<link rel="canonical" href={"https://blog.gymlib.com"+path} />) }
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: "",
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
